<template>
    <CompTable :title="$core.getUrlParam('type') != '' ? '阅读详情' : '群阅读明细'" :columns="columns" :table-api="tableApi">
        <template #operate>
            <div>
                <Button type="primary" @click="changePush()">再次推送未读人员</Button>
            </div>
        </template>
    </CompTable>
</template>

<script>
import CompTable from "../../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        const groupName = this.$core.getUrlParam("gn") || ""

        return {
            informationId: this.$core.getUrlParam("i") || "",
            groupId: this.$core.getUrlParam("gi") || "",

            columns: [
                {
                    title: "微信群名称",
                    render: () => groupName,
                },
                {
                    title: "姓名",
                    key: "username",
                },
                {
                    title: "手机号",
                    key: "mobile",
                },
                // {
                //     title: "阅读时间",
                //     key: "readDate",
                //     width: 250,
                // },
                {
                    title: "阅读情况",
                    key: "readGroupRate",
                    render: (h, p) => `<span style="color:${p.row.read ? "" : "red"}">${p.row.read ? "已读" : "未读"}</span>`,
                },
            ],
            tableApi: "",
        }
    },

    methods: {
        changePush() {
            this.$Modal.confirm({
                title: "是否再次推送未读人员",
                onOk: () => {
                    this.$post(
                        "/gateway/syinfopublish/api/pc/information/v2/informationPUSH",
                        {
                            informationId: this.informationId,
                            communityCode: JSON.parse(sessionStorage.getItem("userDetail")).communityCode,
                            pushChannel: "9",
                            groupList: [this.groupId],
                            orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            staffName: parent.vue.loginInfo.userinfo.realName,
                            oemCode: parent.vue.oemInfo.oemCode,
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        this.$Message.destroy()
                        if (res && res.code == 200) {
                            this.$Message.success({
                                content: "操作成功",
                                background: true,
                            })
                            return
                        } else {
                            this.$Message.error({
                                content: "操作失败",
                                background: true,
                            })
                            return
                        }
                    })
                },
            })
        },
    },

    created() {
        this.tableApi =
            this.$core.getUrlParam("type") && this.$core.getUrlParam("type") != ""
                ? `/syaa/api/syuser/pc/userBaseStat/findUserViewDetail?appId=${this.groupId}&infoId=${this.informationId}`
                : `/info/api/pc/information/group/selectGroupRecord?informationId=${this.informationId}&groupId=${this.groupId}`
        if (this.$core.getUrlParam("type") && this.$core.getUrlParam("type") != "") {
            this.columns = [
                {
                    title: "报表名称",
                    render: () => this.$core.getUrlParam("gn") || "",
                },
                {
                    title: "姓名",
                    key: "username",
                },
                {
                    title: "手机号",
                    key: "mobile",
                },
                {
                    title: "阅读时间",
                    key: "viewTime",
                    width: 250,
                    render: (h, p) => `<span}">${p.row.viewTime && p.row.viewTime != "" ? this.$core.formatDate(new Date(p.row.viewTime), "yyyy-MM-dd hh:mm:ss") : ""}</span>`,
                },
                {
                    title: "阅读情况",
                    key: "viewStatus",
                    render: (h, p) =>
                        `<span style="color:${p.row.viewStatus && p.row.viewStatus != "" && p.row.viewStatus.indexOf("已读") > -1 ? "" : "red"}">${
                            p.row.viewStatus && p.row.viewStatus != "" ? p.row.viewStatus : "未读"
                        }</span>`,
                },
            ]
        }
    },
}
</script>
